<template>
  <div class="Seats">
    <Swipers :banners="banners"></Swipers>
    <div class="Seats-content">
      <div v-if="clientWidth > 750" class="crumb">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div class="Seats-Info">
        <img v-if="clientWidth > 750" src="@/assets/PC/shop/shop-Info.png" width="100%">
        <img v-else src="@/assets/PC/shop/shop-Info1.png" width="100%">
        <div class="Seat-Info" :class="clientWidth < 751 && Infos.id && !Infos.isSchool ? 'flex3' : ''">
          <div class="flex3" :style="clientWidth < 751 && Infos.id ? '' : 'height: 100%'" :class=" clientWidth < 751 && Infos.id ? 'Seat-info-mb' : ''">
            <div class="flex2">
              <img v-if="Infos.vip" src="../../assets/PC/profile/viphead.png" alt="" class="viphead">
              <img v-if="Infos.pictureUrl" :src="Infos.pictureUrl" class="Info-img">
              <img v-else src="@/assets/PC/shop/shop-InfoHead.jpg" class="Info-img">
              <div v-if="Infos.id">
                <h1 class="co-fff Infos-name hidden"> {{ Infos.name }} </h1>
                <p class="Info-badge">
                  <img v-if="clientWidth > 750" :src="Infos.level.web_bck" class="web-bck">
                  <img v-else :src="Infos.level.app_bck" class="app-bck">
                  <span class="co-fff">{{ Infos.level.levelName }}</span>
                </p>
              </div>
              <div v-else>
                <h1>
                  <router-link to="/Login" class="cur co-fff goLogin">去登录</router-link>
                </h1>
              </div>
            </div>
            <div class="Seat-Detail flex" v-if="Infos.id && clientWidth > 750 && Infos.isSchool">
              <div class="Seat-Detail-i Seat-Detail-i1 flex10">
                <span class="tc">您的门派</span>
                <el-tooltip class="item" effect="dark" :content="Infos.respUserInfoSchool.schoolName" placement="top">
                  <p class="co-fff hidden">{{ Infos.respUserInfoSchool.schoolName }}</p>
                </el-tooltip>
              </div>
              <div class="Seat-Detail-i Seat-Detail-i2 flex10">
                <span class="tc">门派人数</span>
                <p class="co-fff">{{ Infos.respUserInfoSchool.schoolNum }}</p>
              </div>
              <div class="Seat-Detail-i Seat-Detail-i3 flex10">
                <span class="tc">门派排名</span>
                <p class="co-fff" v-if="Infos.respUserInfoSchool.ranking === 0">未上榜</p>
                <p class="co-fff" v-else>{{ Infos.respUserInfoSchool.ranking }}</p>
              </div>
              <div class="Seat-Detail-i Seat-Detail-i4 flex10">
                <span class="tc">门派口号</span>
                <el-tooltip class="item" effect="dark" :content="Infos.respUserInfoSchool.slogan" placement="top">
                  <p class="co-fff hidden">{{ Infos.respUserInfoSchool.slogan }}</p>
                </el-tooltip>
              </div>
            </div>
            <div class="Seat-attend tc">
              <el-button v-if="Infos.id && !Infos.isSchool" type="info" :size="clientWidth < 751 ? 'small' : ''" @click="join">我要发帖</el-button>
              <el-button v-if="Infos.id && Infos.isSchool" type="info" :size="clientWidth < 751 ? 'small' : ''" @click="goArticle">我要发帖</el-button>
              <p v-if="Infos.id" class="co-fff mt5 Seat-attend-tip">点击即刻发帖参战</p>
            </div>
          </div>
          <div v-if="clientWidth < 751 && Infos.id && Infos.isSchool" class="flex3">
            <div class="Seat-Detail flex">
              <div class="Seat-Detail-i Seat-Detail-i1 flex10">
                <span class="tc">您的门派</span>
                <el-tooltip class="item" effect="dark" :content="Infos.respUserInfoSchool.schoolName" placement="top">
                  <p class="co-fff hidden">{{ Infos.respUserInfoSchool.schoolName }}</p>
                </el-tooltip>
              </div>
              <div class="Seat-Detail-i Seat-Detail-i2 hidden flex10">
                <span class="tc">门派人数</span>
                <p class="co-fff">{{ Infos.respUserInfoSchool.schoolNum }}</p>
              </div>
              <div class="Seat-Detail-i Seat-Detail-i3 hidden flex10">
                <span class="tc">门派排名</span>
                <p class="co-fff" v-if="Infos.respUserInfoSchool.ranking === 0">未上榜</p>
                <p class="co-fff" v-else>{{ Infos.respUserInfoSchool.ranking }}</p>
              </div>
              <div class="Seat-Detail-i Seat-Detail-i4 hidden flex10">
                <span class="tc">门派口号</span>
                <el-tooltip class="item" effect="dark" :content="Infos.respUserInfoSchool.slogan" placement="top">
                  <p class="co-fff hidden">{{ Infos.respUserInfoSchool.slogan }}</p>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div v-if="IsTabData" class="Seats-texts mt15">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="SeatData.respSchoolType1.key" name="first">
            <div class="Seats-texts-item description" v-html="SeatData.respSchoolType1.text"></div>
          </el-tab-pane>
          <el-tab-pane :label="SeatData.respSchoolType2.key" name="second">
            <el-timeline>
              <el-timeline-item v-for="item in SeatData.respSchoolType2.contents" :key="item.id">
                <div class="inneglectable">
                  <p class="violet inneglectable-title">{{ item.key }}</p>
                  <p class="co-999 ml15">{{ item.contents[0].key }}</p>
                  <ul v-if="item.contents[0].contents.length > 0">
                    <li v-for="(item1,index1) in item.contents[0].contents" :key="index1" class="ml15">
                      {{ item1.key }}
                      <ul v-if="item1.contents.length > 0" class="inneglectable-role ml15">
                        <li v-for="(item2,index2) in item1.contents" :key="index2" class="co-999">
                          {{ item2. key }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
          <el-tab-pane :label="SeatData.respSchoolType3.key" name="third">
            <div class="Favors flex9">
              <div v-for="(item, index) in SeatData.respSchoolType3.respSchoolType3Infos" :key="index" class="Favor-item">
                <div class="Favor-item-img flex">
                  <img v-if="!item.url" src="@/assets/PC/seat/favor.png" alt="" width="100%">
                  <img v-else :src="item.url" alt="" width="100%">
                </div>
                <div class="Favor-item-description flex1 tc">
                  {{ item.content }}
                </div>
                <div class="Favor-item-titles">
                  <img src="@/assets/PC/seat/Rectangle.png" alt="" width="100%">
                  <div class="Favor-item-title" :class="clientWidth < 751 ? 'flex3' : ''">
                    <p class="co-fff" v-if="clientWidth > 750">{{ item.title }}</p>
                    <p class="co-fff">No.{{index + 1}}</p>
                    <p class="co-fff" v-if="clientWidth < 751">{{ item.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="SeatData.respSchoolType4.key" name="fourth">
            <div class="Seats-texts-hero">
              <div class="Seats-hero-title flex3">
                <div class="tc">排名</div>
                <div class="tc">门派</div>
                <div class="tc" v-if="clientWidth > 750">点赞量</div>
                <div class="tc" v-if="clientWidth > 750">评论量</div>
                <div class="tc" v-if="clientWidth > 750">转发量</div>
                <div class="tc">总计</div>
                <div class="tc" v-if="clientWidth > 750">作品数量</div>
                <div class="tc" v-if="clientWidth > 750">门派人数</div>
              </div>  
              <div v-if="IsDataPage" class="Seats-hero-body">
                <div class="mb15" v-if="DataPage.data.length > 0">
                  <div v-for="(item, index) in DataPage.data" :key="item.id" class="Seats-hero-item flex3">
                    <div class="tc Seats-hero-item-child1">
                      <div class="Seats-hero-item-child1-icon">
                        <img v-if="(DataPage.pageIndex - 1) * 10 + (index + 1) == 1" class="badge" src="@/assets/PC/seat/1.png" alt="">
                        <img v-if="(DataPage.pageIndex - 1) * 10 + (index + 1) == 2" class="badge" src="@/assets/PC/seat/2.png" alt="">
                        <img v-if="(DataPage.pageIndex - 1) * 10 + (index + 1) == 3" class="badge" src="@/assets/PC/seat/3.png" alt="">
                        <img v-if="(DataPage.pageIndex - 1) * 10 + (index + 1) > 3" class="badge" src="@/assets/PC/seat/4.png" alt="">
                      </div>
                      <div class="Seats-hero-item-child1-num">
                        <div v-if="clientWidth > 750" :class="(DataPage.pageIndex - 1) * 10 + (index + 1) > 3 ? 'co-999' : ''">{{ (DataPage.pageIndex - 1) * 10 + (index + 1)}}</div>
                        <div v-if="clientWidth < 751" :class="(DataPage.pageIndex - 1) * 10 + (index + 1) > 3 ? 'co-999' : ''">{{ index + 1 }}</div>
                      </div>
                    </div>
                    <div class="tc">{{ item.schoolName }}</div>
                    <div class="tc" v-if="clientWidth > 750">{{ item.likeCount }}</div>
                    <div class="tc" v-if="clientWidth > 750">{{ item.commentCount }}</div>
                    <div class="tc" v-if="clientWidth > 750">{{ item.shareNum }}</div>
                    <div class="tc">{{ item.sumCount }}</div>
                    <div class="tc" v-if="clientWidth > 750">{{ item.forumCount }}</div>
                    <div class="tc" v-if="clientWidth > 750"> {{ item.peopleNum }} </div>
                  </div>
                </div>
                <div class="noData" v-else>
                  <img src="@/assets/PC/profile/listNone.png" alt="" />
                </div>
                <div v-if="IsloadList" class="loadList flex0">
                  <div class="loadList0" @click="loadlist">加载更多</div>
                </div>
                <div v-if="clientWidth < 750 && !IsloadList && DataPage.data.length > 0" class="tc notDetail">
                  <div>
                    没有更多内容了~ 
                    <span class="violet" @click="scrollTop">返回顶部</span>
                  </div>
                </div>
              </div>
              <div class="page tr" v-if="IsDataPage">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :total="DataPage.recordCount"
                  :page-size="DataPage.pageSize"
                  :current-page="DataPage.pageIndex"
                  background
                  layout="pager, next"
                  next-text="下一页"
                  hide-on-single-page
                >
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-loading="loading"></div>
    <div v-if="IsShade" class="shade flex1">
      <div class="shade-content flex1">
        <img v-if="clientWidth > 750" src="@/assets/PC/seat/shade.png" alt="">
        <img v-if="clientWidth < 751" src="@/assets/PC/seat/shade-mb.png" alt="" class="shade-mb">
        <div class="shade-content-close cur" @click="close">
          <i class="el-icon-circle-close co-fff"></i>
        </div>
        <div v-if="IsShade1" class="shade-content-form flex1">
          <div>
            <div v-if="CompanySchool.length == 0">
              <p class="shade-content-form-title co-999">
                少侠，你还未创立门派，作为第一个接受战书的勇士，来为门派取一个名字吧！
              </p>
            </div>
            <div v-else>
              <p class="shade-content-form-title co-999">
                已有以下门派创立：
              </p>
              <div class="shade-content-seat">
                <div class="shade-content-seats ">
                  <div class="flex" v-if="CompanySchool.length < 3">
                    <div v-for="(item, index) in CompanySchool" :key="index" class="shade-seats-item flex2">
                      <div class="shade-seats-item-name hidden">{{item.schoolName}}</div>
                      <div class="shade-seats-item-opeation co-fff flex1 cur" @click="Joins(item.id)">加入</div>
                    </div>
                  </div>
                  <div v-else>
                    <swiper class="swiper-containers1" ref="mySwiper1" :options="swiperOption" :auto-play="true">
                      <swiper-slide v-for="(item, index) in CompanySchool" :key="index" class="swiper-item">
                        <div v-if="item" class="shade-seats-item flex2">
                          <div class="shade-seats-item-name hidden">{{item.schoolName}}</div>
                          <div class="shade-seats-item-opeation co-fff flex1 cur" @click="Joins(item.id)">加入</div>
                        </div>
                      </swiper-slide>
                      <div class="swiper-scrollbar"></div>
                    </swiper>
                  </div>
                  
                </div>
              </div>
              <p class="shade-content-form-title co-999 title0">没有你想加入的门派？自己创立一个吧！</p>
            </div>
            <div class="seat-join-form">
              <el-form :model="seatJoin" ref="seatForm" :rules="rules">
                <el-form-item class="flex" prop="schoolName" :label="clientWidth > 750 ? '门派名字' : ''">
                  <el-input v-model="seatJoin.schoolName" placeholder="请输入您的门派名字"></el-input>
                </el-form-item>
                <el-form-item class="flex" prop="slogan" :label="clientWidth > 750 ? '门派口号' : ''">
                  <div style="display: flex; width: 100%">
                    <el-input v-model="seatJoin.slogan" placeholder="请输入您的门派口号"></el-input>
                  </div>
                </el-form-item>
                <el-form-item class="el-form-btn">
                  <el-button type="info" size="medium" :loading="seatload" @click="seatJoins('seatForm')" class="loginBtn" >创 立</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as seat from '@/api/seat.js';
import { GetBanner } from '@/api/Home.js';
import Swipers from "@/components/Swiper.vue";
import { dataState } from "@/api/all.js";
import breadcrumb from "@/components/Breadcrumb/index.vue"
import { common_msg_update } from "@/utils/common.js"

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);

export default {
  name: 'Seat',
  data() {
    return {
      loading: true,                          // !loading
      seatload: false,                        // !创立门派
      IsloadList: false,                      // !移动端加载更多
      Infos: this.$store.state.Infos,         // !个人信息
      banners: [],                            // !封面图
      refer: [
        { name: "首页", href: "/" },
        { name: "工业精英 煮酒论剑" },
      ],
      IsTabData: false,                       // !接口是否成功返回数据
      activeName: 'first',                    // !tab默认选中
      SeatData: {},                           // !tab数据
      IsShade: false,                         // !加入门派
      IsShade1: false,                        // !门派显示隐藏
      CompanySchool: [],                      // !同公司 其他门派
      seatJoin: {
        schoolName: '',                       // !门派名称
        slogan: '',                           // !门派口号
      },
      IsDataPage: false,                      // !门派排行显示
      DataPage: {
        data: [],
        recordCount: 0,
        pageIndex: 1,
        pageCount: 10,
      },                                      // !门派排行数据
      pages: {
        page: 1,
        limit: 10,
      },                                      // !分页筛选

      rules: {
        schoolName: [
          { required: true, message: "请输入门派名称", trigger: "blur" },
          { min: 1, max: 12, message: "门派名称不能超过12个字符", trigger: "blur"}
        ],
        slogan: [{ required: true, message: "请输入门派口号", trigger: "blur" },
        { min: 1, max: 30, message: "门派口号不能超过30个字符", trigger: "blur"}],
      },
      swiperOption: {
        initialSlide: 0,
        slidesPerView: 2.3,
        speed: 1000, //匀速时间
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        effect: "flip",
      },
    }
  },
  // created() {
  //   this.$toast("活动已结束，感谢您的关注！");
  //   setTimeout(() => {
  //     this.$router.push("/");
  //   }, 10);
  // },
  mounted() {
    if(JSON.stringify(this.$store.state.Infos) != "{}") {
      this.Infos = this.$store.state.Infos;
    }
    // !获取banner图
    GetBanner().then(res=>{
      const windowWidth = document.documentElement.clientWidth;
      if(windowWidth > 750){
        this.banners = res.data.school;
      }else{
        this.banners = res.data.school_m;
      }
    });

    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "Seat",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })

    this.Init()
    this.Unitedranking()
  },
  methods: {
    // !初始化 tab切换
    Init() {
      seat.GetConfig().then(res => {
        if(res.status == 1) {
          this.SeatData = res.data
          this.IsTabData = true
          this.loading = false
        } else {
          this.$toast(res.message)
          this.loading = false
        }
      })
    },
    // !加入门派弹窗
    join() {
      this.IsShade = true;
      seat.GetCompanySchool().then(res => {
        if(res.status == 1) {
          // res.data = ['中国', '美国']
          this.CompanySchool = res.data;
          setTimeout(() => {
            this.IsShade1 = true
          }, 100);
          
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !去写文章页面
    goArticle() {
      if(this.Infos.respUserInfoSchool.schoolNum > 2) {
        this.$router.push('/Home/ForumSec/Article');
      } else {
        this.$toast("您的门派人数不足3人");
      }
    },
    // !关闭弹窗
    close() {
      this.IsShade = false
      this.IsShade1 = true
    },
    // ! 创立门派
    seatJoins(Form) {
      this.$refs[Form].validate((valid) => {
        if (valid) {
          this.seatload = true;
          seat.AddCompanySchool(this.seatJoin).then(res => {
            this.$toast(res.message)
            if(res.status == 1) {
              this.IsShade = false
              common_msg_update()
            } else {
              this.seatload = false
            }
          })
        }
      })
    },
    // !加入门派
    Joins(Id) {
      seat.JoinCompanySchool({'schoolId': Id}).then(res => {
        this.$toast(res.message)
        if(res.status == 1) {
          this.IsShade = false
          common_msg_update()
        } else {
          this.seatload = false
        }
      })
    },
    // !门派排行
    Unitedranking(Ismobile) {
      seat.GetAllSchoolRankingPage(this.pages).then(res => {
        if(res.status === 1) {
          if(res.data.length > 0){
            this.IsDataPage = true;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            }else {
              this.IsloadList = false;
            }
            if(Ismobile && this.clientWidth < 750){
              this.DataPage.data = this.DataPage.data.concat(res.data);
              if(this.DataPage.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.DataPage = res;
            } 
          } else {
            this.IsDataPage = true;
          }
        }
      }) 
    },
    // !分页筛选
    handleCurrentChange(currentPage) {
      this.pages.page = currentPage
      this.Unitedranking()
    },
    handleClick(tab) {
      if (tab.index === "3") {
        this.pages.page = 1;
        this.Unitedranking();
      }
    },
    // !移动端加载更多
    loadlist() {
      if(this.clientWidth < 750){
        this.pages.page++;
        this.Unitedranking(true);
      }
    },
    // !返回顶部
    scrollTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth
    },
    Info() {
      return this.$store.state.Infos
    }
  },
  components: {
    Swipers,
    breadcrumb,
    Swiper,
    SwiperSlide,
  },
  watch: { 
    Info(val) {
      this.Infos = val
    }
  }
}
</script>

<style lang="scss">
  @import './Seat.scss';
</style>