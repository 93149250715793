import request from '@/utils/request'

// !获取 煮酒论剑 数据
export function GetConfig() {
  return request({
    url: '/School/GetConfig',
    method: 'GET',
  })
}

// !获取 当前公司的门派
export function GetCompanySchool() {
  return request({
    url: '/School/GetCompanySchool',
    method: 'GET',
  })
}

// !创建 门派
export function AddCompanySchool(data) {
  return request({
    url: '/School/AddCompanySchool',
    method: 'POST',
    data
  })
}

// !加入 门派
export function JoinCompanySchool(data) {
  return request({
    url: '/School/JoinCompanySchool',
    method: 'POST',
    data
  })
}

// !获取门派 排行
export function GetAllSchoolRankingPage(params) {
  return request({
    url: '/School/GetAllSchoolRankingPage',
    method: 'GET',
    params
  })
}